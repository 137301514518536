// Put all the unneeded beauty-stuffz here


@import "config";

//Colors
$header-background: #000;

//FORM
$form-field-color--placeholder: #dadada;
$form-field-border:             #cecece;



.header {
  width: 100%;
  padding: 15px 0;
  background: $header-background;

  .logo {
    width: 100%;
    margin: auto;
    padding: $grid-spacing;
  }
}

.sheet {
  width: 100%;
  max-width: $max-width;
  margin: auto;
}

.package-builder {
  &-form,
  &-examples {
    padding: $grid-spacing;
  }

  &-form {
    .middle-column {
      position: relative;
      margin-bottom: 10px;
    }
    label {
      display: inline-block;
      width: 200px;
    }

    input,
    select {
      display: inline-block;
      position: relative;
      width: 100%;
      padding: 5px 10px 3px;
      border: 1px solid $form-field-border;
      border-radius: 0;
      outline: none;
      z-index: 2;

      // WebKit, Blink, Edge
      &::-webkit-input-placeholder {
        color: $form-field-color--placeholder;
        transition: inherit;
      }

      // Mozilla Firefox 4 to 18
      &:-moz-placeholder {
        color: $form-field-color--placeholder;
      }

      // Mozilla Firefox 19+
      &::-moz-placeholder {
        color: $form-field-color--placeholder;
      }

      // Internet Explorer 10-11
      &:-ms-input-placeholder {
        color: $form-field-color--placeholder;
      }

      // Microsoft Edge
      &::-ms-input-placeholder {
        color: $form-field-color--placeholder;
      }

      // Most modern browsers support this now.
      &::placeholder {
        color: $form-field-color--placeholder;
      }

      //remove dropdown arrow from IE10
      &::-ms-expand {
        display: none;
      }
    }

    .button-holder {
      width: 100%;
      max-width: 500px;
      margin-top: $grid-spacing;

      &::after {
        display: table;
        clear: both;
        content: "";
      }
    }

    button {
      display: block;
      padding: 8px 35px;
      background: #000;
      color: #fff;
      font-size: 16px;
      border: 0;
      transition: all 0.3s ease-out;
      float: right;

      &:hover,
      &:focus,
      &:active {
        background: #333;
        outline: none;
      }
    }

    select {
      background: transparent;
      appearance: none;
    }

    .select-holder {
      display: inline-block;
      width: 100%;
    }
  }

  &-examples {
    .image-holder {
      background: #f2f2f2;
      margin: auto;
      padding: $grid-spacing;
    }

    img {
      display: block;
      max-width: 100%;
      margin: auto;
    }
  }
}

//Toggler
.image-holder {
  height: 0;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-out
}

.image-toggler-label {
  display: inline-block;
  margin-bottom: $grid-spacing;
  padding: 8px 35px;
  background: #000;
  color: #fff;
  font-family: $font-bold;
  font-size: 16px;
  transition: all 0.3s ease-out;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: #333;
  }
}

.image-toggler {
  display: none;

  &:checked {
    +.image-toggler-holder {
      .image-holder {
        height: auto;
        visibility: visible;
        overflow: visible;
        opacity: 1;
        transition: all 0.3s ease-out
      }
    }
  }
}


////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (min-width: $breakpoint-desktop-small) {
  .header {
    .logo {
      max-width: $max-width;
    }
  }

  .package-builder {
    &-form {
      .notification {
        display: inline-block;
        position: absolute;
        width: 250px;
        padding-left: 15px;
      }

      input,
      select {
        width: 300px;
      }

      .select-holder {
        width: 300px;
      }
    }
  }
}


