$max-width: 1440px;
$grid-spacing: 25px;
$grid-offset: 40px;

$breakpoint-desktop-small:    48em; // 768px // DESKTOP (SMALL)

$font-folder:   '../ennit/';

@font-face {
  font-family: 'NovelPro-Regular';
  font-style: normal;
  src: url('#{$font-folder}/webfonts/23D39D_1_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_1_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_1_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_1_0.ttf') format('truetype');
}
  
@font-face {
  font-family: 'NovelPro-It';
  src: url('#{$font-folder}/webfonts/23D39D_2_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_2_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_2_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NovelPro-Bold';
  src: url('#{$font-folder}/webfonts/23D39D_0_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_0_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_0_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NovelSansPro-Regular';
  src: url('#{$font-folder}/webfonts/23D39D_5_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_5_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_5_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_5_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NovelSansPro-LightIt';
  src: url('#{$font-folder}/webfonts/23D39D_3_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_3_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_3_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'NovelSansPro-Light';
  src: url('#{$font-folder}/webfonts/23D39D_4_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_4_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_4_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_4_0.ttf') format('truetype');
}
@font-face {
  font-family: 'NovelSansPro-BoldIt';
  src: url('#{$font-folder}/webfonts/23D39D_6_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_6_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_6_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_6_0.ttf') format('truetype');
}
  
@font-face {
  font-family: 'NovelSansPro-Bold';
  src: url('#{$font-folder}/webfonts/23D39D_7_0.eot');
  src: url('#{$font-folder}/webfonts/23D39D_7_0.eot?#iefix') format('embedded-opentype'), url('#{$font-folder}/webfonts/23D39D_7_0.woff') format('woff'), url('#{$font-folder}/webfonts/23D39D_7_0.ttf') format('truetype');
}

$font:        'NovelSansPro-Light', 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
$font2:       'NovelSansPro-Regular', 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
$font-lightitalic:  'NovelSansPro-LightIt', 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
$font-bold:     'NovelSansPro-Bold', 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
$font-bolditalic: 'NovelSansPro-BoldIt', 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;

$font-headlines:    'NovelPro-Regular', 'Times New Roman', Times, serif;
$font-headlines-italic: 'NovelPro-It', 'Times New Roman', Times, serif;
$font-headlines-bold: 'NovelPro-Bold', 'Times New Roman', Times, serif;


$font-size:       14px;
$line-height:     25px;
$letter-spacing:    0;

h1,
h2,
h3, 
h4, 
h5,
h6 {
  font-family: $font-headlines;
}

h3 {
  font-size: 24px;
}

strong {
  font-family: $font-bold;
}

body {
  * {
    box-sizing: border-box;
  }
}

.sheet {
  font-family: $font2;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

